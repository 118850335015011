export default {
  "header": {
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "SL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "China": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "Laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "Turkiye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkiye"])}
  },
  "footer": {
    "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "Social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social"])},
    "ICanMeta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan Wallet"])},
    "ICanChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan Chat"])},
    "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICan Properties"])},
    "ServiceAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service agreement"])},
    "PrivacyAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy agreement"])},
    "QRPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund and Return"])},
    "Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policies"])},
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help & Support"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "SocilaMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay Connected"])},
    "SaveApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the app"])}
  },
  "message": {
    "LoginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User login success"])},
    "LoginToMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to iCan Mall"])},
    "SomethingWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "DataDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out expired !"])},
    "AddressAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address added successfully"])},
    "DeleteAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete address successfully"])},
    "UpdateAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address modified successfully"])},
    "ChangeAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping address changed"])},
    "CodeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code sent"])},
    "ResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset success"])},
    "AddFavourits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to favourites"])},
    "ItemOutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out stock"])},
    "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to cart"])},
    "LoggedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logged out"])},
    "PasswordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password updated successfully!"])},
    "ProfileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated successfully!"])},
    "OrderCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order cancelled"])},
    "InvalidNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid mobile number"])},
    "RegisterSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration successful"])},
    "NoFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No filters"])},
    "FreeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free delivery within your region"])},
    "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, There are no reviews here !"])},
    "prodnotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This product does not available in your area"])},
    "AddAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please add your address"])},
    "DelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Order successfully"])},
    "HaveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "CODPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can pay in cash or Bank card to our courier when you received goods at your delivery address."])},
    "cancelledOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled order successfully"])},
    "onlyfiveImgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can upload five images only "])},
    "EnterYourThought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please enter your thoughts"])},
    "CommentsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your comments added successfully"])},
    "NoCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check back soon for exciting new products!"])},
    "ConfirmByCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you confirm payment will be proceed with selected card"])},
    "NoVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing to show in this category!"])},
    "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "FormValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! It looks like you missed a few fields or entered invalid data."])},
    "PageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 - Page Not Found"])},
    "PageNotFoundDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the page you're looking for cannot be found."])},
    "GoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Home"])},
    "minPayAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The payment amount shall not be less than"])},
    "alreadyDefaultAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected address is already your default address"])},
    "DeleteCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully deleted Card"])}
  },
  "headerTitle": {
    "ICanMall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCanMall"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register account"])},
    "ChangePwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["goods"])},
    "IcanServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Services"])},
    "TrendingDeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trending Deals"])},
    "YouMayLike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You May Like"])},
    "MyCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourites"])},
    "Myfootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My footprint"])},
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "DefaultAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add default address"])},
    "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "AccountSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account & security"])},
    "PersonalProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal profile"])},
    "PasswordSecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password security"])},
    "EditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit personal profile"])},
    "PwdChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "ProdDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product description"])},
    "ProdSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Specifications"])},
    "CusReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reviews"])},
    "AbtSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About seller"])},
    "SimilarProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar products"])},
    "AllReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All reviews"])},
    "DeliveryAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery address"])},
    "OrderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Summary"])},
    "TotAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount"])},
    "PaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment methods"])},
    "Summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "Addr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
    "EditAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit address"])},
    "AddNewAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new address"])},
    "ShoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your shopping cart"])},
    "CartEmplty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is empty"])},
    "Recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended for you"])},
    "ToPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pay"])},
    "ToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To deliver"])},
    "ToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To receive"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
    "Speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "Popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
    "searchResultFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Results for"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "Shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shops"])},
    "CoinPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin price"])},
    "CoinRrecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin records"])},
    "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])},
    "Invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My invitation"])},
    "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "Brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands"])},
    "COD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm with COD?"])},
    "OrderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order placed"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier Service"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking Number"])},
    "OrederCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Order"])},
    "RateProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate a Product"])},
    "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "Mall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Lanka PVT Ltd"])},
    "App": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you confirm the recipt"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Vouchers"])},
    "Trending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's Top Trending Deals"])},
    "AppAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan mall App is available now"])},
    "confirmedOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm & Pay"])},
    "SelectPaymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Payment Method"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved cards"])},
    "PaymentOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Options"])}
  },
  "subTitles": {
    "DefaultAddrSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Provide further information"])},
    "IcanMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and pay I acknowledge that my card information is saved in Bank card vault for subsequent transactions. If required, you can remove the card in the \"Payment Options\" section in the \"Account\" menu."])},
    "LoginEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with email"])},
    "LoginMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Mobile"])},
    "Forgotpwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forgot password?"])},
    "CreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your details to create your account"])},
    "RegisterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register with email"])},
    "RegisterMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register with mobile"])},
    "PleaseEnterMobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the registered mobile number"])},
    "PleaceEnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the registered email"])},
    "ResetWithMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset with mobile"])},
    "ResetWithEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset with email"])},
    "AreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
    "Item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
    "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "Additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional description and credentials"])},
    "CancelledDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Details"])},
    "RateProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate your product "])}
  },
  "placeholeds": {
    "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "ShopNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Now"])},
    "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "AddNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New"])},
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "SaveConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save & Confirm"])},
    "PlaceOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order"])},
    "SeeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See All"])},
    "GetCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Code"])},
    "ResetPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "AddCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "BuyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy now"])},
    "WriteReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a review"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "SaveCanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "SelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "DeleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DeleteAll"])},
    "ContinueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Shopping"])},
    "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "ServiceAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Agreement"])},
    "PrivacyAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Agreement"])},
    "ContactSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Seller"])},
    "ViewLogistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Logistics"])},
    "ConfirmReceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Receipt"])},
    "DeleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Order"])},
    "Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate product"])},
    "CancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Order"])},
    "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "ContactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact name"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Town"])},
    "AddrL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address line"])},
    "Zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
    "SearchProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search products"])},
    "VisitStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Store"])},
    "ConfirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "ViewOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View order"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "SubmitReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit a Review"])},
    "AccoutAndSecutiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account & Security"])},
    "Collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect"])},
    "DownloadApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download iCan mall App"])},
    "confirmOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Order"])},
    "continuePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "Loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "LoadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load More"])},
    "PasswdLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Login"])},
    "QuickLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Login"])},
    "ShippinFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Delivery"])},
    "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "NoSaveCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Saved Cards"])},
    "mintPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Now for Cashback / Pay Later Interest-Free Installments"])}
  },
  "formLabels": {
    "PersonalInfor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
    "CountryRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country/Region"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "MobileNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "NewPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "ConfirmPasswd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "VerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])},
    "DefaultAddrlabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as default address"])},
    "Nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "Gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "BD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "Currentpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "InviteCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation code"])},
    "IAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the"])},
    "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled Amount"])},
    "CancelReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the reason for the cancel"])},
    "AddRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your thoughts"])},
    "AddImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add images"])},
    "isAnonymus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate a product as an Anonymous"])},
    "Clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
    "MobileOrPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number or Email"])}
  },
  "Label": {
    "Sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold"])},
    "Vouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOUCHERS"])},
    "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "DeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate delivery date"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "5Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5Stars"])},
    "4Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4Stars"])},
    "3Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3Stars"])},
    "2Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2Stars"])},
    "1Star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1Star"])},
    "Subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "BasketCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baskets in the cart"])},
    "ItemCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Code"])},
    "TotalItemCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total item cost"])},
    "SavedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved by Vouchers"])},
    "PromoCodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo codes"])},
    "Shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Fee"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "SaveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Card Details"])},
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "Buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "OrderNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
    "RefundAmmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Amount"])},
    "Cod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash on delivery"])},
    "InStok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In stock"])},
    "OutOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of stock"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan me"])},
    "AppDisriptionFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The next revolution in e-commerce with amazing"])},
    "AppDisriptionSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offers,and unbelievable prices "])},
    "CopyRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" All Rights Reserved. "])},
    "DefaultAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default delivery address"])},
    "NoAddr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No address Provided"])},
    "VocherCampain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to ican mall! Grab Your Exclusive New User Coupons Now!🎉"])},
    "DownloadDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The next revolution in e-commerce with amazing offers, and unbelievable prices."])},
    "UseAppDiscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the iCan mall app anytime, anywhere to find items, contact with suppliers, manage your orders, and make payments."])},
    "GetApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the app"])},
    "subTot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Total"])},
    "ShipFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "Cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit/Debit Card"])},
    "Deleverytime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time"])},
    "DeliveryService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Service"])},
    "EstimatedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated delivery date"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "TimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time slot"])},
    "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule delivery"])},
    "Availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
    "Ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings"])},
    "Courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier"])},
    "FreeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping"])},
    "DeliveryTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery To"])},
    "DeliveryDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Date"])},
    "EstimateDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate delivery"])},
    "NA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N/A"])},
    "Warranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty"])},
    "YearWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month Seller Warranty"])},
    "WarrantyNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty Not Available"])},
    "ShipFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ship From"])},
    "Overseas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overseas"])},
    "Voucher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vouchers"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "VisitStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit store"])},
    "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add To Cart"])},
    "BuyNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now"])},
    "ProductSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Specifications"])},
    "ChatNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Now"])},
    "SoldOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOLD OUT"])},
    "AddNewCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Card"])}
  },
  "ValidationMsg": {
    "FieldRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "SelectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the country code"])},
    "ValidNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid Mobile number"])},
    "EnterPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password"])},
    "PWRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password cannot contain leading or trailing spaces"])},
    "OTP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter verification code"])},
    "ValiedCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid code"])},
    "Passwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password again"])},
    "PwNotMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password not match"])},
    "EnterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter email"])},
    "ValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid email"])},
    "MobNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter mobile number"])},
    "ValidNickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter nick name"])},
    "PwTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password should be 8 characters long & must include at least one Capital letter, Simple letter, Symbol & number."])},
    "AddrTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of character cannot exceeding 500"])}
  },
  "NotItemsMessage": {
    "notItemcart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no items in your cart"])},
    "notItemTopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no any items to Pay"])},
    "notNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no any notifications"])},
    "notItemToDeliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no any items to deliver"])},
    "notSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry. We cannot find any matches for your search term"])},
    "notFavourit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add an item to your favourites by clicking the heart icon"])},
    "notItemToReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no any items to Receive"])},
    "notCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no any items in Completed"])},
    "notFootprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The footprint is empty!"])},
    "notItemRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no any items to Refund"])},
    "notReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry there are no reviews for this product yet"])}
  },
  "CancelOrderReasons": {
    "Duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate order"])},
    "WrongProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong product description"])},
    "ChangeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed delivery address"])},
    "ChangeMind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I changed my mind"])},
    "FoundCheaper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Found cheaper elsewhere"])},
    "deliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time is too long"])}
  },
  "invitationInfo": {
    "UnlockRewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock Rewards"])},
    "ScantoRefer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan to Refer a Friend!🎉"])},
    "MyInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Invitation"])},
    "Invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited"])},
    "YourRank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Rank"])},
    "confInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed Invitation"])},
    "Leaderboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leaderboard"])},
    "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Refferel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reff"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conf"])}
  },
  "voucherInfo": {
    "CappedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capped at"])},
    "PercentageDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% OFF"])},
    "FreeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping"])},
    "VoucherValidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Through"])},
    "VoucherApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "VoucherApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied"])},
    "VoucherCollect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect"])},
    "VoucherCollected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collected"])},
    "VoucherCollectedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voucher Collected"])},
    "VoucherCollectError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error Occured While collecting the voucher"])},
    "AllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "ProductVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "ShopVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "MallVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCan Mall"])},
    "MyVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Vouchers"])},
    "RecommendedVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended"])},
    "ViewAllVouchers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "ShopVoucherTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon & Discount"])}
  },
  "campain": {
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconds"])}
  },
  "GroupBuy": {
    "groupBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group buy"])},
    "NeedParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["need"])},
    "NoParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more participant"])},
    "timeOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["time out"])},
    "Invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hr"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
    "sec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sec"])},
    "InPeogress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In-Progress"])},
    "Upcomming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])}
  },
  "AddressMobile": {
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative"])},
    "enterAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter alternative number "])},
    "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
    "selectArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the province, district, city"])},
    "yourArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Your Area"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])},
    "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["province"])},
    "District": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["district"])},
    "Town": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["town"])}
  },
  "Languages": {
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "Chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
    "Turkish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkish"])},
    "Russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
    "Portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])}
  },
  "BannerText": {
    "BannerBottomHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock Your Shopping Adventure!"])},
    "BannerBottomSub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download our app now to discover electronics, fashion experiences, amazing offers, and more!"])},
    "BannerBottomSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the Qr code to download iCan Mall"])}
  },
  "OrderDetails": {
    "OrderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details"])},
    "CourierService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier service"])},
    "TrackingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking number"])},
    "Receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "OrderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
    "Copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "PlacedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placed on"])},
    "PaidOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid on"])},
    "Remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
    "Insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery insurance"])},
    "ShopDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop discount"])},
    "SeeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
    "SeeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See less"])},
    "SubmitOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit order"])},
    "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "Shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped"])},
    "Delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
    "Copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copied"])}
  },
  "ContactUs": {
    "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "SubHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real time and fast responses"])},
    "NeedHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
    "ContactEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can contact iCan mall Customer service for help."])},
    "OfficeAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office Address"])},
    "SocialMedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are also available on social media"])}
  },
  "wishList": {
    "GroceryList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grocery List"])},
    "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "CreateList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Grocery List"])},
    "TotalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
    "NothingGrocery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your haven't Any Grocery List"])},
    "NothingGroceryMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your Grocery List"])},
    "CreateNewBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a New List"])},
    "GrocryListLopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New List"])},
    "CreateListValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grocery List name is required"])},
    "CreateListBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create List"])},
    "SelectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
    "AddItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Items"])},
    "ItemListEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Grocery List is Empty"])},
    "ItemEmptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started by adding items that catch your eye"])},
    "AddingItemBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Adding Items"])},
    "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discout Price"])},
    "SubTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Total"])},
    "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Fee"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "ShareList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share List"])},
    "BuyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy List"])},
    "CreateGrecerySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created grocery list successfully"])},
    "FaildeGroceryError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create grocery list"])},
    "DeleteItemssuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Item Successfully"])},
    "SelectedWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the items"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "ItemAddedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item added successfully"])},
    "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
    "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more to save"])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved"])},
    "PlaceholederTextBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Grocery List Name"])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
    "nameAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create grocery list. List name already exists."])}
  }
}