import {createRouter, createWebHistory} from 'vue-router'
import appLayout from "@/layouts/AppLayout";
// import appLayoutWithMenu from "@/layouts/AppLayoutWithMenu";

import { useLoginStore } from '@/stores/userStore';

const routes = [
    {
        path: "/",
        component: appLayout,
        children: [
            {
                path: "",
                name: "HomeView",
                component: () => import("@/views/Home/Home")
            },
            {
                path: "/:categoryList/:categoryId",
                children:[
                    {
                        path: "/:categoryList/:categoryId",
                        name: "CategoryListView",
                        component: () => import("@/views/CategoryList/CategoryList.vue")
                    },
                    {
                        path: "prods/:category",
                        name: "SelectedCategory",
                        component: () => import("@/views/CategoryList/SelectedCategory.vue")
                    },
                ]
            },
            {
                path: "Cart",
                name: "ShoppingCart",
                component: () => import("@/views/ShoppingCart/ShoppingCart")
            },
            {
                path: "MyFootPrint",
                name: "MyFootprint",
                component: () => import("@/views/MyFootprint/MyFootprint")
            },
            {
                path: "products/:productId",
                name: "viewProduct",
                component: () => import("@/views/Product/Product")
            },
            {
                path: "goods",
                name: "viewGoods",
                component: () => import("@/views/Product/Product")
            },

            {
                path: "Checkout",
                name: "CheckoutView",
                component: () => import("@/views/Checkout/CheckoutView")
            },
            {
                path: "SearchResults/:result",
                name: "SearchResultsView",
                component: () => import("@/views/Search/Search")
            },
            {
                path: "categories/",
                name: "CategoryView",
                component: () => import("@/views/Categories/CategoryView.vue")
            },
            {
                path: "categories/",
                name: "CategoriesList",
                component: () => import("@/views/Categories/CategoryView.vue")
            },
            {
                path: "services/:services",
                name: "TagListView",
                component: () => import("@/views/TagList/TagListProductsView.vue")
            },
            {
                path: "category",
                name: "ProductView",
                component: () => import("@/views/ViewAllProducts/ViewAllProducts.vue")
            },
            {
                path: "Settings",
                name: "ProfileSettingsView",
                component: () => import("@/views/ProfileSettings/Settings.vue")
            },
            {
                path: "vouchers",
                name:"Vouchers",
                component: () => import("@/views/Vouchers/VoucherPage.vue")
            },
            {
                path: "ToPay",
                name: "ToPay",
                component: () => import("@/views/ProfileSub/ToPay.vue")
            },
            {
                path: "paymentMethod",
                name: "CheckoutSummary",
                component: ()=> import("@/views/Checkout/CheckoutSummery.vue")
            },
            {
                path: "ToDeliver",
                name: "ToDeliver",
                component: () => import("@/views/ProfileSub/ToDeliver.vue")
            },
            {
                path:"ToDeliver/CancelOrder",
                name:"CancelOrder",
                component: () => import("@/views/ProfileSub/CanceOrderToDeliver.vue")
            },
            {
                path: "ToReceive",
                children: [
                    {
                        path: "",
                        name: "ToReceive",
                        component: () => import("@/views/ProfileSub/ToReceive.vue")
                    },
                    {
                        path: "logistics",
                        name: "Logistics",
                        component: () => import("@/views/ProfileSub/ViewLogistics.vue")
                    }
                ]
            },

            {
                path:"Completed",
                children: [
                    {
                        path: "Completed",
                        name: "Completed",
                        component: () => import("@/views/ProfileSub/Completed.vue")
                    },
                    {
                        path: "RateProduct",
                        name: "rateProducts",
                        component: () => import("@/views/ProfileSub/RateProductView.vue")
                    },
                ]
            },
            {
                path: "RateProduct",
                name: "rateProducts",
                component: () => import("@/views/ProfileSub/RateProductView.vue")
            },
            {
                path: "Refund",
                name: "Refund",
                component: () => import("@/views/ProfileSub/Refund.vue")
            },
            {
                path: "profile",
                name: "profile",
                component: () => import("@/views/Profile/Profile")
            },
            {
                path:"iCanchat",
                name: "chat",
                component: () => import("@/views/Chat/IcanChat.vue")
            },
            {
                path:"collection",
                name:"FavouritsView",
                component: () => import("@/views/Favourit/FavouritesView.vue")
            },
            {
                path: "store/:shop/:storeId",
                name: "store",
                component: () => import("@/views/Store/Store")
            },
            {
                path:"trending",
                name:"trending",
                component: () => import("@/views/Trending/Trending.vue")
            },
            {
                path: "notification",
                name: "notification",
                component: () => import("@/views/Notification/NotificationPage.vue")
            },
            {
                path:"myInvitation",
                name:"Invitation",
                component: () => import("@/views/Invitaions/MyInvitation.vue")
            },
            {
                path:"group-buy",
                name:"GroupBuyHome",
                component: () =>import("@/views/GroupBuy/GroupBuyHome.vue")
            },
            {
                path:"discover",
                children: [
                    {
                        path: "",
                        name:"Discover",
                        component: () =>import("@/views/Discover/DiscoverView.vue")
                    },
                    {
                        path: "sub/:category",
                        name: "SubCat",
                        component: () => import("@/views/Discover/SubComponent/SubCategoryMobile.vue")
                    }
                ]
            },
            {
                path:"wish-list",
                children:[
                    {
                        path:"",
                        name:"CreateWishList", 
                        component: () => import("@/views/WishList/CreateListPage.vue")
                    },
                    {
                        path:"/:listName",
                        name:"ItemList",
                        component: ()=> import("@/views/WishList/ItemListPage.vue")
                    }
                ]
            },
            {
                path:"order-details",
                name:"OrderDetails",
                component: () => import("@/views/ProfileSub/OrderDetails.vue")
            },
            {
                path:"contact-us",
                name:"ContactUs",
                component: () => import("@/views/Footer/ContactUs.vue")
            }
        ]
    },
    {
        path: "/About",
        name: "AboutView",
        component: () => import("@/views/AboutView")
    },

    {
        path: "/Register",
        name: "register",
        component: () => import("@/views/Register/Register")
    },
    {
      path: '/:catchAll(.*)', 
      name: 'PageNotFound',
      component: () => import("@/views/NotFound/NotFoundPage.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})

router.beforeEach((to) => {
    const store = useLoginStore()
    store.setUserCurrentRoute(to.name)
})

export default router
